<template>
  <BodySimple>
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-dashboard-layout-96.png"
          class="h-20 ml-2 inline-block"
        />
        {{ [$store.state.user.first_name, $store.state.user.last_name].join(" ").trim() }}
      </h2>
      <p>
        اینجا پروفایل شماست و فعلا هیچی توش نیست :) اما بعدا یک سری چیز اینجا میگذاریم
      </p>
    </template>
    <template #default>
      <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        <template v-for="(item, index) in options" :key="index">
          <component
            :is="item.link ? 'router-link' : 'div'"
            :to="item.link"
            v-on="item.callFunc ? { click: () => this[item.callFunc]() } : {}"
            class="
              p-3
              bg-blueGray-100
              border-blueGray-200
              hover:bg-blueGray-200
              border
              rounded-md
              flex
              items-center
              cursor-pointer
            "
          >
            <div class="text-lg font-bold w-full">
              {{ item.text }}
            </div>
            <img :src="item.image" class="mx-2 h-24" />
          </component>
        </template>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";

export default {
  components: {
    BodySimple,
  },
  data() {
    return {};
  },
  computed: {
    options() {
      return [
        {
          image: require("@/assets/images/icons8-exit-sign-96.png"),
          text: "خارج شدن از پنل",
          callFunc: "logOut",
        },
      ];
    },
  },
  mounted() {},
  methods: {
    logOut() {
      let $this = this;
      $this.firstLoading = true;
      $this.$axios
        .post("/api/logOut", {
          id: $this.$store.state.user.id,
        })
        .then(function (response) {
          // console.log(response);

          $this.$router.replace({
            name: "Home",
          });
          localStorage.removeItem("token");
          $this.$store.commit("userLogged", false);
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {});
    },
  },
};
</script>
